import { render, staticRenderFns } from "./confirmation.vue?vue&type=template&id=78f67d7c&scoped=true&"
import script from "./confirmation.vue?vue&type=script&lang=js&"
export * from "./confirmation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78f67d7c",
  null
  
)

/* custom blocks */
import block0 from "../i18n.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2FUsers%2Fialiushev%2FProjects%2Fretreat%2Ffront%2Fsrc%2Fviews%2Fexpress%2Ffull%2Fconfirmation.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports