<i18n src="./i18n.json"></i18n>

<template>
  <div class="card">
    <div class="card-header">
<!--      <div>Только для членов <b>КЕМО</b></div>-->

      <router-link class="pull-right" to="full/index">{{
        $t('Full registration')
      }}</router-link>
    </div>
    <form @submit.prevent="validate">
      <div class="card-body row">
<!--        <div class="agreement">-->
<!--          <span-->
<!--            >{{ $t('Agreement warning') }}-->
<!--            <a-->
<!--              target="_blank"-->
<!--              href="http://retreat.kemokiev.org/registracija-na-retrit/"-->
<!--              >{{ $t('agreement rules') }}</a-->
<!--            ></span-->
<!--          >-->
<!--        </div>-->

        <div class="onerow1">
          <label class="label">{{ $t('UID or mobile') }} (380XXXXXXXXX)</label>
          <input
            class="form-control"
            v-model.number="digits"
            ref="digits"
            type="number"
          />
        </div>
        <div class="onerow1">
          <label class="label" for="birthday">{{ $t('Birthday') }}</label>
          <date-component
            id="birthday"
            v-model="birthday"
            v-bind:time="false"
          ></date-component>
        </div>
        <div class="form-group onerow1" v-if="isProduction">
          <vue-recaptcha
            ref="recaptcha"
            size="invisible"
            badge="inline"
            :sitekey="sitekey"
            @verify="register"
            @expired="onCaptchaExpired"
          />
        </div>
      </div>

      <div class="card-footer ">
        <div class=" text-center">
          <div class="col-6 offset-3">
            <button class="btn btn-primary" type="submit">
              {{ $t('Continue') }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
export default {
  name: 'expressLogin',
  data: function() {
    return {
      digits: null,
      birthday: null,
      sitekey: '6LfqIr8UAAAAAFSQs-kGnwoSD4XN84bDpKrpCPsQ'
    };
  },
  methods: {
    validate() {
      if (!this.isProduction) {
        return this.register(null);
      }
      if (!Number.parseInt(this.digits)) {
        return;
      }
      this.$refs.recaptcha.execute();
    },
    register: async function(recaptchaToken) {
      this.$loading(true);
      console.log(this.birthday);
      const postData = {
        digits: Number.parseInt(this.digits),
        birthday: this.$moment(new Date(this.birthday)).format('YYYY-MM-DD'),
        'g-recaptcha-response': recaptchaToken
      };
      try {
        const { body } = await this.postData('/validateUser', postData);
        this.$loading(false);

        const { token, fullname, uid, age, churchId } = body;
        if (1 ||
          [
            3,
            227,
            96,
            91,
            985,
            772,
            106,
            31,
            11566,
            58,
            65,
            10398,
            126,
            11572,
            10388
          ].includes(churchId)
        ) {
          localStorage.digits = uid;
          localStorage.birthday = postData.birthday;
          this.$emit('login', { token, uid, fullname, age });
          return;
        }
        this.$emit(
          'error',
          this.$i18n.t('KJMC member only')
        );
      } catch (e) {
        console.error(e);
        if (e.status === 406) {
          this.$emit('error', this.$t('Error in parameters'));
        }
      }
      this.$loading(false);
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    }
  },
  mounted() {
    if (this.$i18n.locale === 'uk') { // fix stupid language
      this.$i18n.locale = 'ru';
    }
    this.language = this.$i18n.locale;

    if (localStorage.digits) {
      this.digits = localStorage.digits;
      this.birthday = localStorage.birthday;
    }
  },
  computed: {
    isProduction: () => process.env.NODE_ENV === 'production'
  },
  components: { VueRecaptcha }
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/bootstrap.scss';

.agreement {
  @extend .col-10;
  @extend .offset-1;
  text-align: center;
  font-size: 10px;
  margin-top: 10px;
  margin-bottom: 15px;
  & span {
    background-color: #fff9b9;
    padding: 5px;
  }
}
</style>
