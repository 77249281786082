<i18n src="../i18n.json"></i18n>

<template>
  <div class="card">
    <div class="card-header">{{$t('Full registration')}}, {{$t('step')}} 1</div>
    <form @submit.prevent="validate">
      <div class="card-body row">

        <div class="onerow1">
          <label class="label" for="fullname">{{$t('Fullname')}}</label>
          <input
            id="fullname"
            class="form-control"
            v-model.trim="fullname"
            ref="fullname"
            autocomplete="fullname"
            @focus="clearError"
          />
        </div>
        <div class="onerow1">
          <label class="label" for="birthday">{{$t('Birthday')}}</label>
          <date-component
            ref="birthday"
            id="birthday"
            v-model="birthday"
            v-bind:time="false"
            @click="clearError"
          ></date-component>
        </div>
        <div class="onerow1">
          <label class="label" for="sex">{{$t('Gender')}}</label>
          <select v-model="sex" id="sex" class="form-control" ref="sex" @focus="clearError">
            <option value="m">{{$t('M')}}</option>
            <option value="w">{{$t('F')}}</option>
          </select>
        </div>
        <div class="onerow1" @click="country_error=false">
          <auto-complete2
            id="country"
            :alert="country_error"
            :caption="$t('Country')"
            :initial-value="country"
            :source='countries'
            @selected="countrySelected"
            :placeholder="$t('Autocomplete placeholder')"
          ></auto-complete2>
        </div>
        <div class="onerow1" @click="city_error=false">
          <auto-complete2
            id="city"
            :disabled="!country"
            :alert="city_error"
            :caption="$t('City')"
            @addItem="cityAdd"
            :source='cities'
            :initial-value="city"
            @selected="citySelected"
            :can-add=true
            :placeholder="$t('Autocomplete placeholder')"
          ></auto-complete2>
        </div>
        <div class="onerow1">
          <label for="church" class="label">{{$t('Church name')}}</label>
          <input id="church" ref="church" @focus="clearError" autocomplete="organization" class="form-control" v-model.trim="church"/>
        </div>
        <div class="onerow1" @click="ministry_error=false">
          <auto-complete2
            :caption="$t('Ministry')"
            :alert="ministry_error"
            :source='ministries'
            @selected="ministrySelected"
            @focus="clearError"
            :placeholder="$t('Autocomplete placeholder')"
          ></auto-complete2>
        </div>

        <div class="onerow1">
          <label class="label" for="email">{{$t('Email')}}</label>
          <input
            id="email" ref="email"
            @focus="clearError"
            class="form-control"
            type="email"
            autocomplete="email"
            v-model.trim="email"/>
        </div>
        <div class="onerow1">
          <label class="label" for="mobile">{{$t('Cell phone')}}</label>
          <input id="mobile" ref="mobile" @focus="clearError"
                 class="form-control"
                 type="tel"
                 :placeholder=telephone_placeholder
                 autocomplete="tel"
                 v-model.trim="mobile"/>
        </div>
        <div class="onerow1">
          <vue-recaptcha
            ref="recaptcha"
            size="invisible"
            :sitekey="sitekey"
            @verify="step2"
            @expired="onCaptchaExpired"
            badge="inline"

          />
        </div>
      </div>
      <div class="card-footer ">
        <router-link class="btn btn-secondary col-6" to="/express/login">{{$t('Back')}}</router-link>
        <button class="btn btn-primary col-6 " type="submit">{{$t('step')}} 2</button>
      </div>
    </form>
  </div>

</template>

<script>
import AutoComplete2 from '../../../components/user/AutoComplete2';
import VueRecaptcha from 'vue-recaptcha';
export default {
  name: 'expressFullStep1',
  data: function () {
    return {
      fullname: '',
      country: null,
      city: '',
      church: '',
      ministry: '',
      mobile: '',
      email: '',
      birthday: '',
      countries: [],

      telephone_placeholders: {},
      telephone_placeholder: null,
      cities: [],
      ministries: [],
      sex: null,
      country_error: false,
      ministry_error: false,
      city_error: false,
      newCity: null,
      obj: null,
      sitekey: '6LfqIr8UAAAAAFSQs-kGnwoSD4XN84bDpKrpCPsQ',
      mobileCodes: {}
    }
  },
  methods: {
    async countrySelected(obj) {
      this.country = obj && obj.value ? obj.value : null;
      if (this.country) {
        const { body: cities } = await this.getData(`/cities?country_id=${this.country}`);
        this.cities = cities.map(item => {
          return {
            label: this.$i18n.locale === 'ru' ? item.name : item.ename,
            value: item.id
          };
        });
      }
      this.city = null;
      this.telephone_placeholder = this.telephone_placeholders[this.country];
    },
    ministrySelected(obj) {
      this.ministry = obj && obj.value ? obj.value : null;
    },
    citySelected(obj) {
      this.city = obj && obj.value ? obj.value : null;
    },
    validate() {
      const obj = {
        fullname: this.fullname.trim(),
        birthday: this.$moment(this.birthday).format('YYYY-MM-DD'),
        country: this.country,
        city_id: this.city,
        city: this.newCity,
        church: this.church,
        ministry: this.ministry,
        email: this.email,
        mobile: this.mobile.replace(/[^0-9]/g, ''),
        sex: this.sex
      };
      if (obj.fullname.length < 3) {
        this.$refs.fullname.classList.add('error-element');
        return;
      }
      if (!this.$moment(new Date(obj.birthday)).isValid()) {
        document.getElementById('birthday').classList.add('error-element');
        return;
      }
      if (!obj.country) {
        this.country_error = true;
        return;
      }
      if (!Number.isFinite(obj.city_id)) {
        this.city_error = true;
        return;
      }
      if (!obj.church) {
        this.$refs.church.classList.add('error-element');
        return;
      }
      if (!obj.ministry) {
        this.ministry_error = true;
        return;
      }
      if (!obj.sex) {
        this.$refs.sex.classList.add('error-element');
        return;
      }

      if (!obj.email) {
        this.$refs.email.classList.add('error-element');
        return;
      }
      if (!obj.mobile) {
        this.$refs.mobile.classList.add('error-element');
        return;
      }
      const mobileCode = this.mobileCodes[this.country];
      if (mobileCode) {
        const reg = new RegExp(mobileCode);
        if (!reg.test(obj.mobile)) {
          this.$refs.mobile.classList.add('error-element');
          return;
        }
      }
      obj.locale = this.$i18n.locale;
      this.obj = obj;
      this.$refs.recaptcha.execute();
    },
    async step2(recaptchaToken) {
      const obj = Object.assign({
        'g-recaptcha-response': recaptchaToken
      }, this.obj);
      this.$loading(true);
      try {
        await this.postData('/register', obj);
        this.$loading(false);

        this.$router.push('step2');
      } catch (e) {
        this.$loading(false);

        console.error(e);
        this.$refs.recaptcha.reset();
        if (this.$refs[e.body]) {
          this.$refs[e.body].classList.add('error-element');
        }
      }
    },
    cityAdd(name) {
      this.cities.push({
        label: name,
        value: 0
      });
      this.newCity = name;
      this.city = 0;
    },
    clearError() {
      event.target.classList.remove('error-element');
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset()
    }
  },
  async mounted() {
    if (this.$i18n.locale === 'uk') { // fix stupid language
      this.$i18n.locale = 'ru';
    }
    const { body: countries } = await this.getData('/countries');
    this.countries = countries.map(item => {
      return {
        label: this.$i18n.locale === 'ru' ? item.name : item.ename,
        value: item.id
      };
    });
    countries
      .filter(country => country.telephone_placeholder)
      .forEach(country => {
        this.telephone_placeholders[country.id] = country.telephone_placeholder;
        this.mobileCodes[country.id] = country.telephone;
      });

    const { body: ministries } = await this.getData('/ministries');
    this.ministries = ministries.map(item => {
      return {
        label: this.$i18n.locale === 'ru' ? item.name : item.ename,
        value: item.id
      };
    });

    if (this.$refs.fullname) {
      this.$refs.fullname.focus();
    }
    document.getElementById('birthday').addEventListener('click', this.clearError);
  },
  components: {
    AutoComplete2,
    VueRecaptcha
  }
}
</script>

<style scoped lang="scss">
.error-element {
  background-color: #fdd !important;
}

</style>

<style lang="scss">
.label {
  text-transform: uppercase;
  color: #cccccc;
}
</style>
