<i18n src="./i18n.json"></i18n>

<template>
  <div>
    <div class="card" v-if="obj.token || error" @click="clearError">
      <div class="card-header" v-if="error">{{ $t('Error') }}</div>
      <div class="card-header" v-else>
        {{ $t('Express registration') }}: <b>{{ fullname }}</b>
        <i>({{ $t('full years') }}: {{ age }})</i>, UID={{ obj.uid }}
      </div>

      <div class="card-body">
        <div
          v-if="error"
          class="alert alert-danger"
          v-html="error"
          @click="clearError"
        >
          <i class="close fa fa-times-circle" @click="error = null"></i>
        </div>
        <router-view
          v-else
          @login="login"
          @logout="logout"
          @bage="setBage"
          @paymentUrl="setPaymentUrl"
          @periods="setPeriods"
          :codes="codes"
          :usedCodes="usedCodes"
          :rid="rid"
          :retreat="retreat"
          :dailyLimit="dailyLimit"
          @error="gotError"
          :fullname="fullname"
        />
      </div>
      <div class="card-body" style="margin-top: 20px" v-if="!error">
        <div class="row">
          <!-- <div class="slider3">
            <label class="label" @click="bage = !bage">{{
              $t('Including bage')
            }}</label>
            <label class="switch">
              <input type="checkbox" class="success" v-model="bage" />
              <span class="slider round"></span>
            </label>
          </div> -->
          <div class="slider3" v-if="age < 14">
            <label class="label" @click="parentAgree = !parentAgree">{{
              $t('My parents know')
            }}</label>
            <label class="switch">
              <input type="checkbox" class="success" v-model="parentAgree" />
              <span class="slider round"></span>
            </label>
          </div>
          <div class="slider3" v-if="paymentUrl!=='bageOnly'">
            <label class="label " style="margin-top: 8px">{{$t('I need translation into')}}</label>
            <select class=" form-control" style="width: 150px;display: inline-block;" v-model="language">
              <option value="ru">-</option>
              <option v-for="key in Object.keys(languages)" :value="key" :key="key">{{$t(languages[key])}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="card-footer " v-if="!error">
        <div class="row text-center">
          <div class="col-6">
            <button class="btn btn-secondary" @click="logout">
              {{ $t('Back') }}
            </button>
          </div>
          <div class="col-6">
            <button
              class="btn btn-primary"
              @click="processPayment1"
              :disabled="!canPay()"
            >
              {{ $t('Process payment') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <router-view @login="login" @error="gotError" />
    </div>
  </div>
</template>

<script>
import languages from '@/components/languages';

export default {
  name: 'express',
  data: function() {
    return {
      obj: {},
      paymentUrl: null,
      bage: true,
      fullname: null,
      error: null,
      rid: null,
      retreat: '',
      codes: [],
      usedCodes: [],
      dailyLimit: 99999,
      age: 0,
      language: null,
      parentAgree: false,
      periods: []
    };
  },
  methods: {
    login: async function(obj) {
      this.obj = obj;
      this.fullname = obj.fullname;
      this.age = obj.age;
      this.$loading(true);
      const { body } = await this.getData('/retreatData', { uid: obj.uid });
      this.$loading(false);
      let { active, rid, retreat, codes, dailyLimit, usedCodes } = body;
      console.log(body);

      this.codes = codes;
      this.usedCodes = usedCodes;
      this.dailyLimit = dailyLimit;
      this.rid = rid;
      this.retreat = retreat;
      if (active && rid < 100) {
        this.$router.push('active');
        return;
      }
      if (!rid) {
        this.error = this.$i18n.t('there is no retreat now');
        console.log(`error=${this.error}`);
        return;
      }
      this.$router.push('prereg');
      this.$loading(false);
    },
    logout: function() {
      this.obj = {};
      this.$router.push('/express/login');
      this.paymentUrl = null;
      this.bage = false;
    },
    async processPayment() {
      this.$loading(true);
      const postData = {
        token: this.obj.token,
        bage: this.bage,
        language: this.language
      };
      // console.log(postData);
      try {
        const { body } = await this.postData(`/${this.paymentUrl}`, postData);
        if (body.sms) {
          this.$loading(false);
          this.error = body.sms;
          return;
        }
        if (body.reasonCode === 1100) {
          window.location = body.invoiceUrl;
        } else {
          this.$loading(false);
        }
      } catch (e) {
        this.$loading(false);
        this.error = e.body;
        console.error(e);
      }
    },
    async processPayment1() {
      this.$loading(true);
      const postData = {
        token: this.obj.token,
        // bage: this.bage,
        periods: this.periods.join(','),
        language: this.language
      };
      // console.log(postData);
      // if (postData) {
      //   return;
      // }
      try {
        const { body } = await this.postData('march', postData);
        console.log(body);
        if (body.sms) {
          this.$loading(false);
          this.error = body.sms;
          return;
        }
        if (body.reasonCode === 1100) {
          window.location = body.invoiceUrl;
        } else {
          this.$loading(false);
        }
      } catch (e) {
        this.$loading(false);
        this.error = e.body;
        console.error(e);
      }
    },
    gotError(msg) {
      this.error = msg;
    },
    clearError() {
      if (!this.error) {
        return;
      }
      this.error = null;
      this.obj = {};
      this.$router.push('/express/login');
    },
    setBage: function(bage) {
      this.bage = bage;
    },
    setPaymentUrl: function(url) {
      this.paymentUrl = url;
    },
    setPeriods(periods) {
      this.periods = periods;
      // console.log(periods);
    },
    canPay: function() {
      // const result =
      //   Boolean(this.paymentUrl) && (this.age >= 14 || this.parentAgree);
      const result =
        this.periods.length > 0 && (this.age >= 14 || this.parentAgree);

      console.log(`canPay=${result}`);
      return result;
    }
  },
  mounted() {
    if (this.$i18n.locale === 'uk') { // fix stupid language
      this.$i18n.locale = 'ru';
    }

    this.language = this.$i18n.locale;

    if (this.$route.name === 'expressLogin') {
      return;
    }

    if (this.$route.path.match(/express\/full/)) {
      return;
    }
    if (this.obj && !this.obj.token) {
      console.log(this.$route.path);
      this.$router.push('/express/login');
    }
  },
  computed: {
    languages: () => languages
  }
};
</script>

<style lang="scss">
@import '~bootstrap/scss/bootstrap.scss';

.card-body a:first-child {
  margin-bottom: 30px;
}

input[type='radio'] {
  margin-right: 50px;
}

.slider3 {
  padding-bottom: 20px;
  @extend .col-xl-3, .offset-xl-1;
  @extend .col-lg-3, .offset-lg-1;
  @extend .col-md-5, .offset-md-1;
  @extend .col-sm-11, .offset-sm-1;
  @extend .col-12;
  vertical-align: middle;
  /*border-bottom: 1px #ccc dotted;*/
}

.alert .close {
  position: absolute;
  right: -8px;
  top: -12px;
  z-index: 9999;
  cursor: pointer;
}

.switch {
  float: left;
}

.label-table {
  @extend .btn, .btn-outline-success, .btn-lg;
  font-size: 12px;
}
.label {
  @extend .float-left;
  margin-right: 10px;
}
</style>
