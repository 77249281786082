<i18n src="../i18n.json"></i18n>

<template>
  <div class="card">
    <div class="card-header">
      {{ $t('Full registration') }}, {{ $t('step') }} 2
    </div>
    <div class="card-body">
      {{ $t('Check email') }}
    </div>
    <div class="card-footer">
      <button class="btn btn-primary" @click="close">
        {{ $t('Continue') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'expressFullStep2',
  methods: {
    close() {
      this.$router.push('/express');
    }
  },
  mounted: function() {
    if (this.$i18n.locale === 'uk') { // fix stupid language
      this.$i18n.locale = 'ru';
    }
  }
};
</script>

<style scoped lang="scss"></style>
