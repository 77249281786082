<i18n src="../i18n.json"></i18n>

<template>
  <div class="card">
    <div class="card-header">{{ $t('Confirm registration') }}</div>
    <div class="card-body">
      <p v-if="message">
        {{ message }}
      </p>
      <p v-else>{{ $t('Please wait') }}</p>
    </div>
    <div class="card-footer">
      <button class="btn btn-primary" @click="close">{{ $t('Close') }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'confirmation',
  data: function() {
    return {
      message: null
    };
  },
  async mounted() {
    if (this.$i18n.locale === 'uk') { // fix stupid language
      this.$i18n.locale = 'ru';
    }
    try {
      this.$loading(true);
      const { body } = await this.getData(
        `/confirmRegistration/${this.$route.params.uuid}`
      );
      // console.log(body);
      this.message = body;
      this.$loading(false);
    } catch (e) {
      this.$loading(false);
      console.error(e);
    }
  },
  methods: {
    close() {
      this.$router.push('/express');
    }
  }
};
</script>

<style scoped></style>
