import { render, staticRenderFns } from "./prereg.vue?vue&type=template&id=3a9ca96e&scoped=true&"
import script from "./prereg.vue?vue&type=script&lang=js&"
export * from "./prereg.vue?vue&type=script&lang=js&"
import style0 from "./prereg.vue?vue&type=style&index=0&id=3a9ca96e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a9ca96e",
  null
  
)

/* custom blocks */
import block0 from "./i18n.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2FUsers%2Fialiushev%2FProjects%2Fretreat%2Ffront%2Fsrc%2Fviews%2Fexpress%2Fprereg.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports