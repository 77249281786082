<i18n src="./i18n.json"></i18n>

<template>
  <div>
    <div class="prereg-title">
      {{ $t('Registration for') }}: <code>{{ retreat }}</code>
    </div>

    <div class="period onerow " :key="code.code" v-for="code in codes">
      <input
        type="checkbox"
        :value="code.code"
        :id="'code_' + code.code"
        :disabled="dailyLimit <= code.used || isUsed(code.code)"
        @change="pushValue"
      />
      <label
        :for="'code_' + code.code"
        :class="{
          'no-places': dailyLimit <= code.used,
          'is-used': isUsed(code.code)
        }"
        >{{ code.name }}</label
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'expressPrereg',
  data: function() {
    return {
      url: null,
      periods: []
    };
  },
  props: {
    fullname: {
      type: String,
      required: false
    },
    codes: {
      type: Array
    },
    usedCodes: {
      type: Array
    },
    retreat: {
      type: String
    },
    dailyLimit: {
      type: Number
    }
  },
  computed: {
    paymentUrl: {
      set: function(data) {
        this.url = data;
        this.$emit('paymentUrl', data);
      },
      get: function() {
        return this.url;
      }
    }
  },
  methods: {
    pushValue(event) {
      const value = event.target.defaultValue;
      if (this.periods.includes(value)) {
        this.periods = this.periods.filter(item => item !== value);
      } else {
        this.periods.push(value);
      }
      this.$emit('periods', this.periods.sort());
    },
    isUsed(code) {
      // console.log(code);
      // console.log(this.usedCodes);
      if (!Array.isArray(this.usedCodes)) {
        return false;
      }
      return this.usedCodes.includes(code);
    }
  },
  components: {},
  mounted() {
    if (this.$i18n.locale === 'uk') { // fix stupid language
      this.$i18n.locale = 'ru';
    }
  }
};
</script>

<style lang="scss" scoped>
.period {
  label {
    margin-left: 10px;
  }
}
.prereg-title {
  font-size: 1.25rem;
  text-align: center;
}
.no-places {
  text-decoration: line-through;
  color: gray;
}
.is-used {
  color: rgb(8, 128, 8);
  font-weight: bold;
  text-decoration: line-through;
}
</style>
