<i18n src="../i18n.json"></i18n>

<template>
  <div class="card">
    <div class="card-header">{{ $t('Retreat registration') }}</div>
    <div class="card-body">
      <p>{{ $t('info1') }}</p>
      <p>{{ $t('info2') }}</p>
      <p>{{ $t('info3') }}</p>
      <p>{{ $t('info4') }}</p>
    </div>
    <div class="card-footer">
      <router-link class="pull-left btn btn-success" to="/express">{{
        $t('Express')
      }}</router-link>
      <router-link class="pull-right btn btn-primary" to="step1">{{
        $t('Continue')
      }}</router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: 'expressFull',
  methods: {},
  mounted: function() {
    if (this.$i18n.locale === 'uk') { // fix stupid language
      this.$i18n.locale = 'ru';
    }
  }
};
</script>

<style scoped lang="scss"></style>
