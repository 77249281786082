<i18n src="./i18n.json"></i18n>

<template>
    <div class="row ">
        <label class="col3 first label-table" :class="{'active': paymentUrl==='bageOnly'}">
            <input type="radio" name="paymentUrl" value='bageOnly' v-model="paymentUrl" autocomplete="off">
            {{$t('Bage only')}}
        </label>
        <label class="label-table col3 " :class="{'active': paymentUrl==='daily'}">
            <input type="radio" name="paymentUrl" value='daily' v-model="paymentUrl" autocomplete="off">
            {{$t('Daily')}}
        </label>
        <label class="label-table col3 " :class="{'active': paymentUrl==='allDays'}">
            <input type="radio" name="paymentUrl" value='allDays' v-model="paymentUrl" autocomplete="off">
            {{$t('All days')}}
        </label>
    </div>
</template>

<script>
  export default {
    name: 'expressActive',
    props: {
      fullname: {
        type: String,
        required: false
      }
    },
    data: function () {
      return {
        url: null
      }
    },
    computed: {
      paymentUrl: {
        set: function (data) {
          this.url = data;
          this.$emit('paymentUrl', data);
        },
        get: function () {
          return this.url;
        }
      }
    }
  }
</script>

<style scoped lang="scss">
    @import '~bootstrap/scss/bootstrap.scss';
    .col3{
        @extend .col-xl-2;
        @extend .offset-xl-1;
        @extend .col-lg-2;
        @extend .col-md-5;
        @extend .offset-md-1;
        @extend .col-sm-8;
        @extend .offset-sm-2;

    }
    .col3.first{
        @extend .offset-xl-2;
        @extend .offset-lg-2;
    }
</style>
